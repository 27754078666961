import request from '@/utils/request'

const bonded_cargo_spaceApi = {
  bonded_cargo_space_list: '/bonded_cargo_space/',
  bonded_cargo_space_create: '/bonded_cargo_space/',
  bonded_cargo_space_update: '/bonded_cargo_space/',
  bonded_cargo_space_delete: '/bonded_cargo_space/'
}

/**
 * 列表
 */
export function bonded_cargo_space_list (parameter) {
  return request({
    url: bonded_cargo_spaceApi.bonded_cargo_space_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_cargo_space_create (parameter) {
  return request({
    url: bonded_cargo_spaceApi.bonded_cargo_space_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function bonded_cargo_space_update (parameter, bonded_cargo_space_id) {
  return request({
    url: bonded_cargo_spaceApi.bonded_cargo_space_update + bonded_cargo_space_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_cargo_space_delete (bonded_cargo_space_id) {
  return request({
    url: bonded_cargo_spaceApi.bonded_cargo_space_delete + bonded_cargo_space_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
