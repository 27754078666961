var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-row',{attrs:{"gutter":[16,16]}},[_c('a-col',{attrs:{"span":24}},[(!this.$route.params.goods_id)?_c('a-form-item',{attrs:{"label":"货物"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bonded_goods_id',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'bonded_goods_id',\n                {\n                  rules: [\n                    { required: false, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"选择"},on:{"change":_vm.handleaddGoodsChange}},_vm._l((this.goodsList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),(!this.$route.params.id)?_c('a-form-item',{attrs:{"label":"保税商品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bonded_commodity_id',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'bonded_commodity_id',\n                {\n                  rules: [\n                    { required: false, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"选择"}},_vm._l((this.batchList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.batch))])}),1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"选择保税仓库"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bonded_warehouse_id',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'bonded_warehouse_id',\n                {\n                  rules: [\n                    { required: false, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"选择"},on:{"change":_vm.handleWarehouseChange}},_vm._l((_vm.bonded_warehouseList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"选择货位编号"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'bonded_cargo_space_id',
                {
                  rules: [
                    { required: false, message: '请选择' },
                  ],
                },
              ]),expression:"[\n                'bonded_cargo_space_id',\n                {\n                  rules: [\n                    { required: false, message: '请选择' },\n                  ],\n                },\n              ]"}],attrs:{"show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"选择"},on:{"search":_vm.handleSearch}},_vm._l((_vm.cargo_spaceList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"数量"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['count', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]),expression:"['count', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]"}]})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }