import request from '@/utils/request'

const bonded_goodsApi = {
  bonded_goods_list: '/bonded_goods/',
  bonded_goods_create: '/bonded_goods/',
  bonded_goods_update: '/bonded_goods/',
  bonded_goods_delete: '/bonded_goods/',
  bonded_goods_category_list: '/bonded_goods/category/',
  bonded_goods_category_create: '/bonded_goods/category/',
  bonded_goods_category_update: '/bonded_goods/category/',
  bonded_goods_category_delete: '/bonded_goods/category/',
  bonded_goods_attachment_list: '/bonded_goods/attachment/',
  bonded_goods_attachment_create: '/bonded_goods/attachment/',
  bonded_goods_attachment_delete: '/bonded_goods/attachment/',
  bonded_goods_category_bonded_goods_list: '/bonded_goods/category/bonded_goods/',
  bonded_goods_category_bonded_goods_create: '/bonded_goods/category/bonded_goods/',
  bonded_goods_category_bonded_goods_delete: '/bonded_goods/category/bonded_goods/'
}

/**
 * 列表
 */
export function bonded_goods_attachment_list (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_attachment_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
export function bonded_goods_category_bonded_goods_list (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_category_bonded_goods_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_goods_category_bonded_goods_create (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_category_bonded_goods_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function bonded_goods_attachment_create (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_attachment_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_goods_category_bonded_goods_delete (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_category_bonded_goods_delete,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function bonded_goods_attachment_delete (bonded_goods_id) {
  return request({
    url: bonded_goodsApi.bonded_goods_attachment_delete + bonded_goods_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 列表
 */
export function bonded_goods_list (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_goods_create (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function bonded_goods_update (parameter, bonded_goods_id) {
  return request({
    url: bonded_goodsApi.bonded_goods_update + bonded_goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_goods_delete (bonded_goods_id) {
  return request({
    url: bonded_goodsApi.bonded_goods_delete + bonded_goods_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function bonded_goods_category_list (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_category_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function bonded_goods_category_create (parameter) {
  return request({
    url: bonded_goodsApi.bonded_goods_category_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function bonded_goods_category_update (parameter, bonded_goods_id) {
  return request({
    url: bonded_goodsApi.bonded_goods_category_update + bonded_goods_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function bonded_goods_category_delete (bonded_goods_id) {
  return request({
    url: bonded_goodsApi.bonded_goods_category_delete + bonded_goods_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
