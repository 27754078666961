<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24" id="goodslist">
            <a-form-item label="保税仓位">
              <a-auto-complete
                allow-clear
                v-model="queryParam.bonded_cargo_space_id"
                placeholder="请输入保税仓位"
                option-label-prop="tt"
                @search="onSearchBondedCargoSpace"
              >
                <template slot="dataSource">
                  <a-select-option v-for="item in dataSourceOutWarehouse" :tt="item.name" :key="item.id + ''">
                    {{ item.name }}
                  </a-select-option>
                </template>
              </a-auto-complete>
              <span>{{ tip_out_warehouse }}</span>
            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.BondedCommodityStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 120px">
        {{ text }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template v-if="record.status === 1">
          <a @click="inwarehouse(record)">入库</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreatePurchaseForm'
import {
  bonded_commodity_purchase_create,
  bonded_commodity_purchase_delete,
  bonded_commodity_purchase_list,
  bonded_commodity_purchase_update
} from '@/api/bonded_commodity'
import { bonded_cargo_space_list } from '@/api/bonded_cargo_space'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    AAutoComplete
  },
  data () {
    return {
      dataSourceOutWarehouse: [],
      tip_out_warehouse: '',
      previewurl: '',
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '货物',
          dataIndex: 'bonded_goods'
        },
        {
          title: '保税商品',
          dataIndex: 'bonded_commodity'
        },
        {
          title: '仓库',
          dataIndex: 'bonded_warehouse'
        },
        {
          title: '仓库库位',
          dataIndex: 'bonded_cargo_space'
        },
        {
          title: '数量',
          dataIndex: 'count',
          align: 'center',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.BondedCommodityStatus[text]
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return bonded_commodity_purchase_list(Object.assign(parameter, this.queryParam, { bonded_goods_id: this.$route.params.goods_id, bonded_commodity_id: this.$route.params.id }))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {},
  methods: {
    //  out
    onSearchBondedCargoSpace (searchText) {
      bonded_cargo_space_list({ name: searchText }).then((res) => {
        const result = res.data.entries || []
        if (result.length === 0) {
          this.tip_out_warehouse = '未找到您输入的仓位'
        } else {
          this.tip_out_warehouse = ''
        }
        this.dataSourceOutWarehouse = !searchText ? [] : result
      })
    },
    handleAdd () {
      this.mdl = {}
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handlefujian (record) {
      this.current_bill_id = record.id
    },
    handleDelet (record) {
      bonded_commodity_purchase_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
    },
    inwarehouse (record) {
      bonded_commodity_purchase_update({}, record.id).then((res) => {
        this.$refs.table.refresh(true)
      })
    },
    handleOk (e) {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.bonded_commodity_id = this.$route.params.id || values.bonded_commodity_id
          values.bonded_goods_id = this.$route.params.goods_id || values.bonded_goods_id
          bonded_commodity_purchase_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
          .catch((err) => {
            console.log(err)
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
<style lang="less">
  #goodslist .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 0px;
  }
  #goodslist .ant-select-selection__rendered {
    position: relative;
    display: block;
    margin-right: 0px;
    margin-left: 0px;
    line-height: 30px;
  }
</style>
