<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="货物" v-if="!this.$route.params.goods_id">
              <a-select
                @change="handleaddGoodsChange"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'bonded_goods_id',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="item.id" :value="item.id" v-for="item in this.goodsList">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="保税商品" v-if="!this.$route.params.id">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'bonded_commodity_id',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="item.id" :value="item.id" v-for="item in this.batchList">{{ item.batch }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="选择保税仓库">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleWarehouseChange"
                v-decorator="[
                  'bonded_warehouse_id',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="item in bonded_warehouseList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="选择货位编号" >
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                @search="handleSearch"
                v-decorator="[
                  'bonded_cargo_space_id',
                  {
                    rules: [
                      { required: false, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option v-for="item in cargo_spaceList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="数量">
              <a-input v-decorator="['count', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { bonded_warehouse_list } from '@/api/bonded_warehouse'
import { bonded_cargo_space_list } from '@/api/bonded_cargo_space'
import { bonded_commodity_list } from '@/api/bonded_commodity'
import { bonded_goods_list } from '@/api/bonded_goods'

// 表单字段
const fields = ['id', 'number', 'corporation_id', 'total_payment']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      batchList: [],
      goodsList: [],
      is_show: false,
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      bonded_warehouseList: [],
      cargo_spaceList: [],
      cargo_space_copy: [],
      selected_bonded_warehouse_id: undefined
    }
  },
  created () {
    console.log('custom modal created-----------------------', this.form)
    this.get_bonded_warehouse()
    this.get_bonded_cargo_space()
    this.getBondedGoods()
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    filterOption (input, option) {
      console.log(input, option.componentOptions.children[0].text)
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // goods
    getBondedGoods () {
      bonded_goods_list({ page_size: 1000 }).then(({ data }) => {
        console.log(data)
        this.goodsList = data.entries || []
      })
    },
    handleaddGoodsChange (value) {
      console.log(value)
      let model = this.goodsList.find((item) => {
        return item.id === value
      })
      console.log(model)
      this.getbatchCommodity(value)
    },
    getbatchCommodity (bonded_goods_id) {
      bonded_commodity_list({ bonded_goods_id: bonded_goods_id, page_size: 1000 }).then(({ data }) => {
        console.log(data)
        this.batchList = data.entries || []
        this.form.setFieldsValue({
          bonded_commodity_id: ''
        })
      })
    },
    get_bonded_warehouse () {
      bonded_warehouse_list({ page_size: 1000 })
        .then(({ data }) => {
          console.log(data, '仓库@')
          this.bonded_warehouseList = data.entries
        })
    },
    get_bonded_cargo_space () {
      bonded_cargo_space_list({ page_size: 15 })
        .then(({ data }) => {
          console.log(data, '仓位位置@')
          this.cargo_space_copy = data.entries
        })
    },

    handleLifeChange (value) {
      console.log(`selected ${value}`)
      this.is_show = !!value
    },
    handleWarehouseChange (value) {
      console.log(`selected----------------------- ${value}`)
      this.selected_bonded_warehouse_id = value
      this.cargo_spaceList = this.cargo_space_copy.filter((item) => {
        return item.bonded_warehouse_id === value
      })
      console.log(this.cargo_spaceList)
    },
    handleSearch (value) {
      bonded_cargo_space_list({ name: value, bonded_warehouse_id: this.selected_bonded_warehouse_id })
        .then(({ data }) => {
          this.cargo_spaceList = data.entries
        })
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
